<template>
  <a
    :href="`/activity/${post.type_id}/${post.slug}`"
    target="_blank"
    style="min-height: 100%"
  >
    <div class="rounded" style="min-height: 100%">
      <v-container fluid grid-list-lg pa-0 ma-2>
        <v-layout row>
          <v-flex xs5>
            <v-img
              :src="post.images[0].thumbnail"
              :alt="post.images[0].thumbnail"
              height="120px"
              width="128px"
              style="border-radius: 5px !important"
            >
            </v-img>
          </v-flex>
          <v-flex xs7 pa-1 style="padding: 10px 0px !important">
            <div>
              <p
                style="
                  font-size: 14px;
                  max-width: calc(100% - 30px);
                  font-weight: 600;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 0px;
                "
                >{{ post.name }}</p
              >

              <div
                style="
                  display: flex;
                  align-items: center;
                  gap: 6px;
                  flex-wrap: wrap;
                "
              >
                <span style="font-size: 10px">{{ post.location }}</span>
                <div
                  style="
                    width: 5px;
                    height: 5px;
                    background: #d9d9d9;
                    border-radius: 100%;
                  "
                ></div>
                <span style="font-size: 10px">{{ post.duration }} Hours</span>
                <div
                  style="
                    width: 5px;
                    height: 5px;
                    background: #d9d9d9;
                    border-radius: 100%;
                  "
                ></div>

                <span style="font-size: 10px"
                  >{{ post.cancelation }} hrs cancelation</span
                >
              </div>

              <v-flex d-inline-flex pa-0 align-center>
                <v-rating
                  v-model="post.rating"
                  background-color="#757575"
                  color="#FF711A"
                  dense
                  size="15"
                ></v-rating>
                <span
                  style="
                    margin-left: 4px;
                    font-size: 12px;
                    font-weight: 700;
                    margin-top: 5px;
                  "
                  >{{
                    post.rating % 1 === 0
                      ? post.rating + ".0"
                      : post.rating.toFixed(1)
                  }}</span
                >
                <span
                  style="margin-left: 4px; font-size: 12px; margin-top: 5px"
                >
                  ( {{ post.comments_count }} Review )
                </span>
              </v-flex>
              <div style="display: flex; gap: 5px; align-items: center">
                <span
                  style="font-size: 12px; font-weight: 400; color: #303135"
                  >{{ $t("from") }}</span
                >
                <div>
                  <span style="font-size: 14px !important; font-weight: 600">
                    {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}</span
                  >
                  <span style="font-size: 14px !important; font-weight: 600">
                    {{
                      $root.$i18n.locale == "id"
                        ? post.price_idr
                        : post.price_usd | fm
                    }}</span
                  >
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </a>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style scoped>
.rating {
  background-color: #37464d;
  color: white;
  padding: 0 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.cancel {
  color: white;
  font-size: 8px;
  background-color: #739a47;
  padding: 4px 6px;
  margin: 4px 0;
  border-radius: 2px;
}

.rigth {
  text-align: right !important;
}
.top {
  vertical-align: top;
}

.v-card {
  min-height: 100% !important;
}

a:hover,
a:visited,
a:link,
a:active {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

.rounded {
  border-radius: 14px !important;
  background-color: #fff !important;
  border: 1px solid #e0e0e0 !important;
}

.card {
  min-height: 100% !important;
}
</style>

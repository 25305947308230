var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"100% !important"}},[_c('v-card',{staticClass:"rounded custom black--text",attrs:{"elevation":"4","to":{
      name: 'activity',
      params: { slug: _vm.post.slug, type: _vm.post.type.slug },
    },"target":"_blank"}},[_c('div',{staticStyle:{"position":"relative","width":"100%","height":"100%"}},[(
          _vm.post.label_detail || (_vm.language === 'id' && _vm.post.label_detail_id)
        )?_c('div',{staticClass:"badge",style:({
          backgroundColor:
            _vm.language === 'id' ? _vm.post.label_color_id : _vm.post.label_color,
        })},[_vm._v(" "+_vm._s(_vm.language === "id" ? _vm.post.label_detail : _vm.post.label_detail_id)+" ")]):_vm._e(),_c('v-img',{staticStyle:{"border-radius":"9px 9px 0 0"},attrs:{"src":_vm.post.images[0].thumbnail,"alt":_vm.post.images[0].thumbnail,"height":_vm.moneyLarge ? '169px' : '111px',"width":"100%"}})],1),_c('v-card-text',{staticStyle:{"min-height":"100% !important"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',[_c('div',{staticClass:"a-title",class:{ 'one-line': _vm.moneyLarge },style:({
                fontSize: _vm.moneyLarge ? '16px' : '12px',
                marginBottom: _vm.moneyLarge ? '10px' : '5px',
              })},[_vm._v(" "+_vm._s(_vm.post.name)+" ")]),_c('div',{class:{ 'flex-parent': _vm.moneyLarge }},[(!_vm.moneyLarge)?_c('div',[_c('i',{staticClass:"mdi mdi-star",staticStyle:{"color":"orange"}}),_vm._v(" "+_vm._s(_vm.post.rating)+" / "),_c('span',{staticStyle:{"color":"gray"}},[_vm._v("("+_vm._s(_vm.post.comment_count || 0)+" reviews)")])]):_vm._e(),_c('div',{staticClass:"flex-custom"},[_c('img',{staticStyle:{"width":"16px","margin-right":"5px"},attrs:{"src":require("@/assets/images/icons/maps-marker.svg"),"alt":"arrow-right"}}),_c('p',{staticStyle:{"color":"#000"}},[_vm._v(_vm._s(_vm.post.location))])]),(_vm.moneyLarge)?_c('div',[_c('i',{staticClass:"mdi mdi-star",staticStyle:{"color":"orange"}}),_vm._v(" "+_vm._s(_vm.post.rating)+" / "),_c('span',{staticStyle:{"color":"gray"}},[_vm._v("("+_vm._s(_vm.post.comment_count || 0)+" reviews) ")])]):_vm._e()])]),_c('v-divider',{class:{ 'my-4': _vm.moneyLarge, 'my-1': !_vm.moneyLarge }}),_c('div',{class:_vm.moneyLarge ? 'flex-parent-custom' : 'flex-parent'},[_c('small',{class:{ caption: !_vm.moneyLarge, 'float-right': _vm.moneyLarge },staticStyle:{"color":"gray"}},[_vm._v(_vm._s(_vm.$t("start-from")))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.moneyLarge),expression:"!moneyLarge"}],staticClass:"title",staticStyle:{"color":"#000","font-size":"12px !important"}},[_c('b',[_vm._v(" "+_vm._s(_vm.$root.$i18n.locale == "id" ? "IDR " : "USD ")+" "+_vm._s(_vm._f("fm")(_vm.$root.$i18n.locale == "id" ? _vm.post.price_idr : _vm.post.price_usd))+" ")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.moneyLarge),expression:"moneyLarge"}],staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.$root.$i18n.locale == "id" ? "IDR " : "USD ")+" "+_vm._s(_vm._f("fm")(_vm.$root.$i18n.locale == "id" ? _vm.post.price_idr : _vm.post.price_usd))+" ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
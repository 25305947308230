<template>
  <div style="position: relative">
    <div class="header-contanier2" v-show="isVisible">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 14px 8px;
          background-color: #f6f6f6;
        "
      >
        <div style="display: flex; gap: 5px; align-items: center">
          <div class="back-btn" @click="closeThisTab">
            <v-btn
              icon
              class="back-btn"
              style="
                background-color: white;
                padding: 4px;
                color: #000 !important;
                font-size: 16px !important;
              "
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <div class="search-container" style="margin-left: 10px">
            {{
              post.name.length > 30
                ? post.name.substring(0, 30) + "..."
                : post.name
            }}
          </div>
        </div>
        <v-btn
          icon
          @click="copyToClipboard"
          style="background-color: white; padding: 4px; color: #000 !important"
        >
          <v-icon class="share_icon">mdi-share-variant</v-icon>
        </v-btn>
      </div>
      <div
        class="pa-0 scrolling-wrapper"
        pa-0
        style="margin-bottom: 0 !important"
      >
        <div
          v-for="item in categories"
          :key="item.name"
          style="max-width: max-content"
        >
          <div @click="selected(item.id)" style="max-width: max-content">
            <div v-if="active != item.id" style="max-width: max-content">
              <div class="card">
                <div>{{ item.name }}</div>
              </div>
            </div>
            <div v-else style="max-width: max-content">
              <div class="card active">
                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :loading="loading"></loading>
    <div v-if="post" v-show="!loading">
      <div style="position: relative">
        <v-btn
          color="white"
          class="black--text"
          style="
            border-radius: 9px;
            position: absolute;
            top: 12px;
            left: 12px;
            z-index: 1;
            min-width: 36px !important;
            max-width: 36px !important;
            padding: 4px !important;
          "
          @click="closeThisTab"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          color="white"
          class="black--text"
          style="
            border-radius: 9px;
            position: absolute;
            top: 12px;
            right: 12px;
            z-index: 1;
            min-width: 36px !important;
            max-width: 36px !important;
            padding: 4px !important;
          "
          @click="copyToClipboard"
        >
          <v-icon class="share_icon">mdi-share-variant</v-icon>
        </v-btn>
        <VueSlickCarousel
          ref="c1"
          :asNavFor="$refs.c2"
          :focusOnSelect="true"
          :arrows="false"
          :dots="false"
        >
          <div v-for="(photo, index) in post.images" :key="index">
            <div
              class="gallery-item-card-large"
              style="
                height: 251px !important;
                min-height: 251px !important;
                max-height: 251px !important;
              "
            >
              <img
                :src="photo.thumbnail"
                :alt="photo.thumbnail"
                class="gallery-image"
                @click="dialogImage = true"
                style="
                  height: 100% !important;
                  min-height: 100% !important;
                  max-height: 100% !important;
                  width: 100% !important;
                  object-fit: cover;
                "
              />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <VueSlickCarousel
        ref="c2"
        :asNavFor="$refs.c1"
        :slidesToShow="2.5"
        :focusOnSelect="true"
        :arrows="false"
        :dots="false"
        class="gallery-carousel"
      >
        <div v-for="(photo, index) in post.images" :key="index">
          <div class="gallery-item-card">
            <img
              :src="photo.thumbnail"
              :alt="photo.thumbnail"
              class="gallery-image"
            />
          </div>
        </div>
      </VueSlickCarousel>
      <v-container fluid grid-list-md pa-0>
        <v-layout row wrap>
          <v-flex xs12 md7>
            <v-dialog
              v-model="dialogImage"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card color="grey darken-4">
                <v-flex xs12>
                  <v-btn
                    class="ma-4"
                    icon
                    dark
                    @click.native="dialogImage = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 text-sm-center class="center">
                  <v-carousel class="carousel-img" hide-delimiters>
                    <v-carousel-item v-for="(image, i) in post.images" :key="i">
                      <img
                        class="img"
                        style="margin-top: 120px"
                        :src="image.original"
                        alt=""
                      />
                    </v-carousel-item>
                  </v-carousel>
                </v-flex>
              </v-card>
            </v-dialog>

            <v-layout row wrap px-3 id="detail" style="background-color: #fff">
              <v-flex xs12>
                <div
                  class="breadcrumbs"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 12px !important;
                    color: black;
                  "
                >
                  <div>
                    <router-link
                      :to="{ name: 'home' }"
                      style="color: black; text-decoration: none"
                    >
                      {{ $t("home") }}
                    </router-link>
                    <v-icon small>mdi-chevron-right</v-icon>
                    <router-link
                      target="_blank"
                      :to="{ name: 'activity' }"
                      style="color: black; text-decoration: none"
                    >
                      {{ $t("just-activity") }}
                    </router-link>
                    <v-icon small>mdi-chevron-right</v-icon>
                    <router-link
                      to="#"
                      style="color: black; text-decoration: none"
                    >
                      {{
                        post.type.name.toLowerCase().charAt(0).toUpperCase() +
                        post.type.name.toLowerCase().slice(1)
                      }}
                    </router-link>
                  </div>
                  <div
                    class="badge"
                    style="
                      background-color: #ffc242;
                      border: 1px solid #ff6100;
                      padding: 2px 8px;
                      border-radius: 5px;
                    "
                  >
                    {{ post.code }}
                  </div>
                </div>
              </v-flex>

              <v-flex xs12>
                <div class="activity-title">{{ post.name }}</div>
              </v-flex>

              <div
                style="
                  display: flex;
                  gap: 8px;
                  flex-wrap: wrap;
                  margin-top: 5px;
                  font-size: 12px !important;
                  margin-bottom: 8px;
                "
              >
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px 4px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.location }}
                </div>
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px 4px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.required }} Person
                </div>
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px 4px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.duration }} Hours
                </div>
                <div
                  style="
                    color: #757575;
                    background-color: #f5f5f5;
                    padding: 2px 4px;
                    border-radius: 5px;
                    max-width: max-content;
                  "
                >
                  {{ post.cancelation }} hrs Cancelation
                </div>
              </div>

              <div class="mt-3">
                <v-row align="center" no-gutters>
                  <!-- Rating Box -->
                  <v-col cols="auto">
                    <div class="rating-box">
                      <span class="rating-value">
                        {{
                          Number.isInteger(post.rating)
                            ? post.rating + ".0"
                            : post.rating
                        }}
                      </span>
                    </div>
                  </v-col>

                  <v-col cols="auto" class="ml-2 mt-2">
                    <img
                      v-for="n in 5"
                      :key="n"
                      src="@/assets/images/stars-orange.svg"
                      class="stars-icon ml-1"
                      alt="stars Icon"
                    />
                    <!-- <v-icon v-for="n in 5" :key="n" color="orange" size="24">mdi-star</v-icon> -->
                  </v-col>

                  <!-- Review Count -->
                  <v-col cols="auto" class="ml-2">
                    <span class="review-text"
                      >( {{ post.comments_count }} Review )</span
                    >
                  </v-col>

                  <!-- Booking Count -->
                  <v-col cols="auto" class="ml-2">
                    <span class="booking-text"
                      >( {{ post.jumlah_book || 0 }} booked )</span
                    >
                  </v-col>
                </v-row>

                <div
                  class="badge-label"
                  v-if="
                    post.label_detail ||
                    (language === 'id' && post.label_detail_id)
                  "
                  :style="{
                    backgroundColor:
                      language === 'id'
                        ? post.label_color_id
                        : post.label_color,
                  }"
                >
                  {{
                    language === "id" ? post.label_detail_id : post.label_detail
                  }}
                </div>

                <div
                  px-3
                  style="
                    margin-top: 14px;
                    padding: 10px 0 !important;
                    font-size: 16px !important;
                    font-weight: 400;
                  "
                >
                  {{ post.short_des }}
                </div>
              </div>
            </v-layout>

            <div class="mt-2 py-3" style="background-color: white">
              <v-flex xs12 px-3>
                <v-expansion-panels>
                  <v-expansion-panel popout>
                    <v-expansion-panel-header>
                      <b>{{ $t("detail-information") }}</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <activity-detail :post="post" />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
            </div>

            <v-layout row wrap>
              <v-container fluid style="border-bottom: 25px solid #f6f6f6">
                <div style="margin-bottom: 8px">
                  <div class="highlight" id="highlight">
                    <v-flex
                      xs12
                      style="display: flex; align-items: center; gap: 5px"
                    >
                      <img
                        src="@/assets/images/highlight-new.svg"
                        :alt="title"
                      />
                      <b
                        style="color: #ff6100 !important; font-size: 18px"
                        class="orange--text text-darken-1"
                        >{{ $t("highlight") }}</b
                      >
                    </v-flex>
                    <v-flex v-html="post.highlight"></v-flex>
                  </div>
                </div>
              </v-container>

              <v-flex xs12 d-inline-flex>
                <div id="description">
                  <v-card
                    elevation="1"
                    style="
                      margin-top: -15px;
                      padding-top: 20px;
                      box-shadow: none !important;
                      border-bottom: 14px solid #f6f6f6;
                    "
                  >
                    <div style="margin: 0 16px">
                      <b class="before_text" style="font-size: 20px">{{
                        $t("description")
                      }}</b>
                    </div>
                    <v-card-text style="font-size: 16px !important">
                      <div v-html="post.activity_des"></div>
                    </v-card-text>
                  </v-card>
                  <div
                    style="
                      margin-bottom: 12px;
                      padding: 12px;
                      box-shadow: none !important;
                      border-bottom: 14px solid #f6f6f6;
                    "
                    v-if="post.packages.length"
                    id="pricing"
                  >
                    <!-- create div line orange block height full width 4px rounded -->
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-bottom: 20px;
                      "
                    >
                      <div
                        style="
                          background-color: #ff6100;
                          height: 18px;
                          width: 4px;
                          border-radius: 20px;
                        "
                      ></div>

                      <b class="before_text_new">
                        {{ $t("pricing-detail") }}
                      </b>
                      <p style="margin-bottom: 0 !important; font-size: 12px">
                        *{{ $t("please_select_package_detail") }}
                      </p>
                    </div>

                    <div
                      style="
                        display: flex;
                        gap: 10px;
                        margin-bottom: 20px;
                        flex-wrap: wrap;
                      "
                    >
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            color="black"
                            v-bind="attrs"
                            v-on="on"
                            style="
                              border-radius: 20px;
                              border-color: black;
                              font-size: 12px;
                              text-transform: capitalize;
                            "
                          >
                            <v-icon left>mdi-calendar</v-icon>
                            {{ all_dates_text }}
                          </v-btn>
                        </template>
                        <v-date-picker
                          v-model="choosenDate"
                          no-title
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                      <v-btn
                        outlined
                        color="black"
                        @click="
                          choosenDate = new Date().toISOString().substr(0, 10)
                        "
                        style="
                          border-radius: 20px;
                          border-color: black;
                          font-size: 12px;
                          text-transform: capitalize;
                        "
                        :class="{
                          active:
                            choosenDate ===
                            new Date().toISOString().substr(0, 10),
                        }"
                      >
                        {{
                          new Date().toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                          })
                        }}
                      </v-btn>
                      <v-btn
                        outlined
                        color="black"
                        @click="
                          choosenDate = new Date(Date.now() + 86400000)
                            .toISOString()
                            .substr(0, 10)
                        "
                        style="
                          border-radius: 20px;
                          border-color: black;
                          font-size: 12px;
                          text-transform: capitalize;
                        "
                        :class="{
                          active:
                            choosenDate ===
                            new Date(Date.now() + 86400000)
                              .toISOString()
                              .substr(0, 10),
                        }"
                      >
                        {{
                          new Date(Date.now() + 86400000).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "short",
                            }
                          )
                        }}
                      </v-btn>
                      <!-- <v-btn
                        outlined
                        color="black"
                        @click="
                          choosenDate = new Date(Date.now() + 2 * 86400000)
                            .toISOString()
                            .substr(0, 10)
                        "
                        style="
                          border-radius: 20px;
                          border-color: black;
                          font-size: 12px;
                          text-transform: capitalize;
                        "
                        :class="{
                          active:
                            choosenDate ===
                            new Date(Date.now() + 2 * 86400000)
                              .toISOString()
                              .substr(0, 10),
                        }"
                      >
                        {{
                          new Date(
                            Date.now() + 2 * 86400000
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                          })
                        }}
                      </v-btn> -->
                    </div>

                    <price-detail
                      :packages="post.packages"
                      @booking-now="bookingDialog = true"
                      @update-total="recalculateBookingData"
                      :choosendate="choosenDate"
                      :confirmation_detail="post.confirmation_detail"
                      :whattobring="post.whattobring"
                      :pickup_time="post.pickup_time"
                      :how_to_use="post.how_to_use"
                      :payment="post.payment"
                      :additional="post.additional"
                      :location="post.location"
                      :duration="post.duration"
                      :cancelation="post.cancelation"
                      :totalAmount="totalAmount"
                      @open-booking="handleBookingPackage"
                    ></price-detail>
                  </div>

                  <div id="rate_includes">
                    <v-card
                    elevation="1"
                    mb-2
                    style="
                      margin-bottom: 12px;
                      box-shadow: none !important;
                      border-bottom: 14px solid #f6f6f6;
                    "
                  >
                    <v-card-text>
                      <div>
                        <b class="before_text mb-3" style="font-size: 18px">{{
                          $t("rate-includes")
                        }}</b>
                        <div
                          class="check--green"
                          style="font-size: 16px !important"
                          v-html="post.included"
                        ></div>
                        <br />
                      </div>
                    </v-card-text>
                  </v-card>
                  </div>

                  <v-flex xs12 px-3>
                    <b class="before_text mb-5" style="font-size: 18px" id="more_info">{{
                      $t("other-info")
                    }}</b>

                    <v-expansion-panels style="margin-bottom: 20px">
                      <v-expansion-panel popout v-if="post.confirmation_detail">
                        <v-expansion-panel-header>
                          <div
                            style="display: flex; align-items: center; gap: 5px"
                          >
                            <img
                              src="@/assets/images/activity-information.svg"
                              :alt="title"
                              height="24px"
                            />
                            <b id="confirmation">{{
                              $t("activity-information")
                            }}</b>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="confirmation">
                          <v-flex v-html="post.confirmation_detail"></v-flex
                          ><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel popout text v-if="post.whattobring">
                        <v-expansion-panel-header>
                          <div
                            style="display: flex; align-items: center; gap: 5px"
                          >
                            <img
                              src="@/assets/images/what-to-bring.svg"
                              :alt="title"
                              height="24px"
                            />
                            <b id="what_to_bring">{{ $t("what-to-bring") }}</b>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="what_to_bring">
                          <v-flex v-html="post.whattobring"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel popout text v-if="post.pickup_time">
                        <v-expansion-panel-header>
                          <div
                            style="display: flex; align-items: center; gap: 5px"
                          >
                            <img
                              src="@/assets/images/pickup.svg"
                              :alt="title"
                              height="24px"
                            />
                            <b id="pickup">{{ $t("pickup-time") }}</b>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="pickup">
                          <v-flex v-html="post.pickup_time"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel popout text v-if="post.how_to_use">
                        <v-expansion-panel-header>
                          <div
                            style="display: flex; align-items: center; gap: 5px"
                          >
                            <img
                              src="@/assets/images/terms.svg"
                              :alt="title"
                              height="24px"
                            />
                            <b id="how_to_use">{{ $t("how-to-use") }}</b>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="how_to_use">
                          <v-flex v-html="post.how_to_use"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel popout v-if="post.payment">
                        <v-expansion-panel-header>
                          <div
                            style="display: flex; align-items: center; gap: 5px"
                          >
                            <img
                              src="@/assets/images/payment-new.svg"
                              :alt="title"
                              height="24px"
                            />
                            <b id="payment"
                              >{{ $t("payment") }}
                              {{ $t("and-cancelation-policy") }}</b
                            >
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="payment">
                          <v-flex v-html="post.payment"></v-flex><br />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-card
          elevation="0"
          class="px-3 pb-4"
          style="
            margin-bottom: 10px;
            box-shadow: none !important;
            border-bottom: 14px solid #f6f6f6;
          "
        >
          <div class="additonal" v-if="post.note">
            <v-flex xs12 class="mb-3">
              <v-icon color="orange">mdi-information</v-icon>
              <b class="orange--text text-darken-1 ms-3" id="additional_info">{{
                $t("additional-information")
              }}</b>
            </v-flex>
            <v-flex v-html="post.note"></v-flex><br />
          </div>
        </v-card>
        <v-container fluid id="reviews">
          <all-activity-reviews
            :posts="comments"
            :overview="overview"
            :activity="post"
            :slug="$route.params.slug"
            :type="$route.params.type"
          ></all-activity-reviews>
        </v-container>
      </v-container>
    </div>

    <v-flex class="sticky" pa-0 xs12 background-color="white">
      <v-divider></v-divider>
      <v-container grid-list-xs pa-0>
        <v-layout row wrap pl-4 pr-2 align-center>
          <v-flex xs7>
            <small>{{ $t("starting-from") }}</small
            ><br />
            <span class="green-text">
              {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}
              {{
                $root.$i18n.locale == "id"
                  ? bookingdata.total
                  : bookingdata.total | fm
              }}
            </span>
            <small>{{ $t("price-post-price_type", [post.price_type]) }}</small>
          </v-flex>
          <v-flex xs4 class="text--right">
            <dialog-booking
              :dialog="bookingDialog"
              :bookingdata="bookingdata"
              :choosenDate="choosenDate"
              :post="post"
              :type="type"
              @bookingData="bookingData"
              @bookingCancel="bookingDialog = false"
            />
            <dialog-confirm
              :dialog="confirmDialog"
              :bookingdata="bookingdata"
              :post="post"
              :type="type"
              @confirmSuccess="confirmSuccess"
              @confirmCancel="confirmDialog = false"
            ></dialog-confirm>
            <v-btn
              depressed
              rounded
              color="blue-grey darken-3 white--text text-capitalize"
              block
              @click="bookingDialog = true"
            >
              <span class="subheading">{{ $t("book-now") }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <v-dialog v-model="bookingSuccessDialog" persistent max-width="290">
      <v-card>
        <img class="success--image" src="@/assets/images/done.png" alt="" />
        <v-card-text>
          <div class="text--center">
            <span class="headline deep-orange--text text-darken-1">{{
              $t("thank-you")
            }}</span
            ><br />
            <span class="subheading"
              >{{
                $t(
                  "please-wait-for-our-confirmation-and-check-your-email-inbox"
                )
              }}.</span
            >
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="bookingSuccessDialog = false">{{
            $t("ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <button
      id="faq"
        @click="$router.push({ name: 'helpandfaq' })"
        style="
          display: inline-flex;
          align-items: center;
          width: 100%;
          border: 1px solid #757575;
          background-color: transparent;
          color: #000;
          justify-content: center;
          align-items: center;
          margin: 10px 0 0 0;
          font-size: 16px !important;
          font-weight: 700;
          padding: 8px 8px;
          border-radius: 9px;
          box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        "
      >
        <img
          src="@/assets/images/faq-new.svg"
          alt="FAQ"
          style="width: 33px; height: 33px; margin-right: 8px"
        />
        {{ $t("faqs") }}
      </button>
    </v-container>

    <v-container fluid>
      <v-flex xs12>
        <v-flex mt-2 mb-2>
          <span
          id="discover-more"
            style="
              font-size: 16px !important;
              font-weight: 700;
              margin-bottom: 16px !important;
            "
            >{{ $t("discover-more-bali-activities") }}</span
          >
        </v-flex>
        <v-flex v-for="(post, i) of others" :key="i" mt-2>
          <activity-card-detail :post="post"></activity-card-detail>
        </v-flex>
      </v-flex>
    </v-container>

    <div class="box">
      <v-layout>
        <v-flex xs12 items-center text-center>
          <img
            src="@/assets/images/icons/livechat-new.svg"
            height="auto"
            alt="Contact Us"
            style="margin: auto; width: 84px"
          /><br />
          <h4
            class="text-h4"
            style="
              font-size: 14px !important;
              text-align: center;
              font-weight: 600;
            "
          >
            Live Support
          </h4>
          <span class="caption">{{
            $t("any_question_about_balitripcenter")
          }}</span
          ><br />
          <a class="email" href="https://wa.me/6285857082012" target="_blank">
            <span class="caption">+62 85857082012</span> </a
          ><br />
          <a class="email" href="mailto:support@balitripcenter.com">
            <span class="caption">support@balitripcenter.com</span>
          </a>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      active: 1,
      commentsloading: false,
      post: null,
      isVisible: false,
      type: null,
      terjemah: [],
      others: [],
      errors: [],
      totalAmount: 0,
      // choosen date today default
      choosenDate: new Date().toISOString().substr(0, 10),
      categories: [],
      categoriesEn: [
        { id: 3, name: "Description", linKHref: "#description" },
        { id: 4, name: "Pricing Detail", linKHref: "#pricing" },
        { id: 5, name: "Rate Includes", linKHref: "#rate_includes" },
        { id: 6, name: "More Info", linKHref: "#more_info" },
        { id: 7, name: "Additional Info", linKHref: "#additional_info" },
        { id: 8, name: "Reviews", linKHref: "#reviews" },
        { id: 9, name: "FAQ", linKHref: "#faq" },
        { id: 10, name: "Find More", linKHref: "#discover-more" },
      ],
      categoriesId: [
        { id: 3, name: "Deskripsi", linKHref: "#description" },
        { id: 4, name: "Detail Harga", linKHref: "#pricing" },
        { id: 5, name: "Harga Termasuk", linKHref: "#rate_includes" },
        { id: 6, name: "Info Lain", linKHref: "#more_info" },
        { id: 7, name: "Info Tambahan", linKHref: "#additional_info" },
        { id: 8, name: "Ulasan", linKHref: "#reviews" },
        { id: 9, name: "FAQ", linKHref: "#faq" },
        { id: 10, name: "Temukan Lainnya", linKHref: "#discover-more" },
      ],
      commentsUrl: null,
      comments: [],
      totalComments: null,
      commentDialog: false,
      loginDialog: false,
      menu: false,
      overview: null,
      dialogImage: false,
      bookingSuccessDialog: false,
      bookingdata: null,
      bookingDialog: false,
      confirmDialog: false,
      settings: {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 2.5,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
      },
      all_dates_text: "All Dates",
    };
  },
  mounted() {
    this.fetchData();
    if (localStorage.getItem("preferredLanguage") === "en") {
      this.categories = this.categoriesEn;
    } else {
      this.categories = this.categoriesId;
    }
    window.addEventListener("scroll", this.detectActiveSection);
  },
  watch: {
    $route: "fetchData",
    choosenDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.all_dates_text = this.choosenDate;
      }
    },
  },
  methods: {
    closeThisTab() {
      window.close();
    },

    recalculateBookingData() {
      // Recalculate total & build order_data from post.packages
      let total = 0;
      const orderData = [];

      this.post.packages.forEach((pkg) => {
        pkg.products.forEach((product) => {
          if (product.ordered > 0) {
            const price =
              this.$root.$i18n.locale === "id"
                ? product.price_idr
                : product.price_usd;
            total += price * product.ordered;

            orderData.push({
              id: product.id,
              name:
                this.$root.$i18n.locale === "id"
                  ? product.name_id
                  : product.name_en,
              price,
              ordered: product.ordered,
            });
          }
        });
      });

      // Update the parent's bookingdata
      this.bookingdata = {
        date: this.choosenDate, // or however you're storing the date
        order_data: orderData,
        total: total,
      };
    },

    handleBookingPackage() {
      this.bookingDialog = false;
      this.confirmDialog = true;
    },

    selected(id) {
      this.active = id;
      const category = this.categories.find((cat) => cat.id === id);
      if (category) {
        const element = document.querySelector(category.linKHref);
        if (element) {
          const offset = -120;
          const bodyRect = document.body.getBoundingClientRect().top;
          const elementRect = element.getBoundingClientRect().top;
          const elementPosition = elementRect - bodyRect;
          const offsetPosition = elementPosition + offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    },
    detectActiveSection() {
      let currentActive = null;
      const offset = 200; // Adjust for header height

      this.categories.forEach((category) => {
        const element = document.querySelector(category.linKHref);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= offset) {
            currentActive = category.id;
          }
        }
      });

      if (currentActive !== null) {
        this.active = currentActive;
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(window.location.href);
      this.$toast.success(this.$t("link-copied"));
    },
    fetchData() {
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/activity/${
            this.$route.params.type
          }/${this.$route.params.slug}?lang=${this.$root.$i18n.locale}&tag=${
            this.$route.query.tag || ""
          }`
        )
        .then((response) => {
          this.loading = false;
          this.type = response.data.items.type;
          this.post = response.data.items;
          this.terjemah = response.data.terjemah;
          this.others = response.data.others;

          this.totalAmount = this.post.packages.reduce((acc, pkg) => {
            return (
              acc +
              pkg.products.reduce((prodAcc, product) => {
                const price =
                  this.$root.$i18n.locale === "id"
                    ? product.price_idr
                    : product.price_usd;
                return prodAcc + price * product.minimal;
              }, 0)
            );
          }, 0);

          this.bookingdata = {
            date: this.choosenDate,
            order_data: [],
            total: this.totalAmount,
          };
          this.post.packages.forEach((pkg) => {
            const price =
              this.$root.$i18n.locale === "id" ? pkg.price_idr : pkg.price_usd;
            this.bookingdata.order_data.push({
              id: pkg.id,
              name:
                this.$root.$i18n.locale === "id" ? pkg.name_id : pkg.name_en,
              price: price,
              ordered: pkg.minimal,
            });
          });
        })
        .then((response) => {
          this.commentsUrl = `${process.env.VUE_APP_API_URL}/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`;
          this.getComments();
        })
        .catch((e) => {
          this.loading = false;
          this.errors.push(e);
        });
    },
    getComments() {
      this.commentsloading = true;
      axios
        .get(this.commentsUrl)
        .then((response) => {
          this.commentsloading = false;
          this.comments = response.data.items;
          this.overview = response.data.overview;
          this.commentsUrl = response.data.items.next_page_url;
          this.totalComments = response.data.items.total;
        })
        .catch((e) => {
          this.commentsloading = false;
          this.errors.push(e);
        });
    },
    isUser() {
      if (this.$store.getters.isAuthenticated) {
        this.commentDialog = true;
      } else {
        this.loginDialog = true;
      }
    },
    loginSuccess() {
      this.loginDialog = false;
      this.commentDialog = true;
    },
    commentSuccess() {
      this.commentDialog = false;
      this.getComments();
    },
    setTotalAmount(newTotal) {
      this.totalAmount = newTotal;
    },
    bookingData(bookingdata) {
      this.bookingDialog = false;
      this.confirmDialog = true;
      this.bookingdata = bookingdata;
      console.log(this.bookingdata);
    },
    bookingCancel() {
      this.bookingDialog = false;
      this.bookingdata = null;
    },
    confirmCancel() {
      this.confirmDialog = false;
    },
    confirmSuccess(response) {
      this.confirmDialog = false;
      this.bookingSuccessDialog = true;
    },
    showHeader() {
      this.isVisible = window.scrollY > 450;
      console.log(this.isVisible);
    },
  },
  created() {
    window.addEventListener("scroll", this.showHeader);
    window.addEventListener("scroll", this.detectActiveSection);
    // window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.showHeader);
    window.addEventListener("scroll", this.detectActiveSection);
    // window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    url() {
      return `${process.env.VUE_APP_API_URL}/api/activity/${this.$route.params.type}/${this.$route.params.slug}/comment`;
    },
  },
};
</script>
<style scoped>
.header-contanier2 {
  position: fixed !important;
  top: 0px;
  z-index: 777;
  width: 100%;
  background-color: #f6f6f6;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.badge-label {
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  max-width: max-content;
  margin-top: 20px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  background-color: white;
  padding: 0px 8px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.scrolling-wrapper .card {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  margin: 0 8px;
  height: auto;
  min-height: 40px;
  border: 4px solid white;
  padding: 4px 0 !important;
  font-weight: 400;
  flex: 0 0 auto;
  font-size: 14px;
  margin-top: 5px !important;
  scroll-snap-align: center;
}

.header-contanier2 .scrolling-wrapper .card.active {
  background-color: white;
  border-bottom-color: #ff6100;
  color: #ff6100;
}

.green-text {
  color: #0fac39;
  font-weight: bold;
  font-size: 1.4rem;
  display: block;
}

.threebox .custom-divider {
  height: 40px;
  /* Adjust the height to align with the content */
  margin: auto -1px;
  border-color: #212121 !important;
}

/* Remove inner borders while keeping the outer border */
.threebox .v-card {
  border: 1px solid #212121 !important;
}

.threebox .v-layout {
  border: none;
}

.email,
.email:hover {
  text-decoration: none;
  color: black;
}

.v-breadcrumbs {
  padding: 8px !important;
  font-size: 12px;
}

.top {
  vertical-align: top;
}

.sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}

.carousel-img {
  width: 100%;
  height: 240px;
  align-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.success--image {
  height: 100px;
  width: 100%;
  object-fit: scale-down;
  justify-content: center;
  margin-top: 12px;
}

.text--center {
  text-align: center;
}

.center {
  height: 80vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 440px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .img {
    width: 100%;
    height: 440px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-img {
    width: 100%;
    height: 340px;
    align-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .img {
    width: 100%;
    height: 340px;
    object-fit: cover;
  }
}

.v-center {
  vertical-align: middle;
}

.h-center {
  text-align: center;
}

.rating {
  background-color: #37464d;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}

.additonal {
  border-color: #ff6100;
  border-width: 2px;
  border-style: solid;
  padding: 18px 8px;
  background-color: #fffde7;
  border-radius: 4px;
}

.additional ul {
  padding-left: 30px !important;
}

.highlight {
  padding: 18px 8px;
  background-color: #f4f7fe;
  border-radius: 20px;
}

.additional ul {
  padding-left: 30px !important;
}

.before_text {
  background-image: url("~@/assets/images/before_title.svg");
  background-repeat: no-repeat;
  padding-left: 15px;
  /* width of the image plus a little extra padding */
  display: inline-flex;
  /* may not need this, but I've found I do */
  font-size: 16px;
  font-weight: 600;
  background-position: 0% 50%;
}

.before_text_new {
  background-repeat: no-repeat;
  padding: 5px 18px;
  display: inline-flex;
  /* may not need this, but I've found I do */
  font-size: 16px;
  border: 1px solid #ff6100;
  border-radius: 20px;
  font-weight: 600;
}

div.check--green {
  list-style-image: url("~@/assets/images/check.svg");
}

.bread {
  color: black;
  text-decoration: none;
}

.bread:hover {
  text-decoration: none;
  color: black;
}

.product_id {
  font-weight: bold;
  height: 25px;
  font-size: 12px;
  position: relative;
}

.vertical-center {
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.vertical-center img {
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-center .text {
  width: 100%;
  height: 20px;
  margin: 0;
  left: 25px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.activity-title {
  margin-top: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.box {
  margin: 8px;
  padding: 8px;
  border-radius: 12px;
}

.box img {
  margin-top: 10px;
}

.gallery-item-card {
  max-height: 105px;
  margin-left: 5px;
}

.gallery-item-card-large {
  max-height: 251px;
}

.gallery-item-card-large img {
  width: 100%;
  min-width: 100%;
  height: 251px;
  object-fit: cover;
  max-width: 251px;
  min-height: 251px;
}

.gallery-item-card img {
  width: 100%;
}

.rating-box {
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
}

.review-text,
.booking-text {
  color: #000;
  font-size: 12px;
}

.share_icon {
  font-size: 15px;
}
</style>

<template>
  <v-card
    class="black--text"
    style="box-shadow: none;"
    :to="{
      name: 'activity',
      params: { slug: post.slug, type: post.type.slug },
    }"
    target="_blank"
  >
    <v-container fluid grid-list-lg pa-0>
      <v-layout row>
        <v-flex xs5>
          <v-img
            :src="post.images[0].thumbnail"
            :alt="post.images[0].thumbnail"
            height="120px"
            width="128px"
            style="border-radius: 5px !important"
          >
          </v-img>
        </v-flex>
        <v-flex xs7 pa-1 style="padding: 10px 0px !important">
          <div>
            <span
              style="
                font-size: 14px;
                font-weight: 600;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{ post.name }}</span
            >

            <div
              style="
                display: flex;
                align-items: center;
                gap: 6px;
                flex-wrap: wrap;
              "
            >
              <span style="font-size: 10px">{{ post.location }}</span>
              <div
                style="
                  width: 5px;
                  height: 5px;
                  background: #d9d9d9;
                  border-radius: 100%;
                "
              ></div>
              <span style="font-size: 10px">{{ post.duration }} Hours</span>
              <div
                style="
                  width: 5px;
                  height: 5px;
                  background: #d9d9d9;
                  border-radius: 100%;
                "
              ></div>

              <span style="font-size: 10px"
                >{{ post.cancelation }} hrs cancelation</span
              >
            </div>

            <v-flex d-inline-flex pa-0 align-center>
              <v-rating
                v-model="post.rating"
                background-color="#757575"
                color="#FF711A"
                dense
                size="15"
              ></v-rating>
              <span style="margin-left: 4px; font-size: 12px;font-weight:700;margin-top: 5px;">{{
                post.rating % 1 === 0
                  ? post.rating + ".0"
                  : post.rating.toFixed(1)
              }}</span>
              <span style="margin-left: 4px; font-size: 12px;margin-top: 5px;">
                ( {{post.comments_count}} Review )
              </span>
            </v-flex>
            <div style="display:flex; gap:5px;align-items:center;">
              <span style="font-size: 12px; font-weight: 400; color: #303135">{{
                $t("from")
              }}</span>
              <div>
                <span style="font-size: 14px !important; font-weight: 600">
                  {{ $root.$i18n.locale == "id" ? "IDR " : "USD " }}</span
                >
                <span style="font-size: 14px !important; font-weight: 600">
                  {{
                    $root.$i18n.locale == "id"
                      ? post.price_idr
                      : post.price_usd | fm
                  }}</span
                >
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>
<script>
export default {
  props: ["post"],
};
</script>
<style>
.rating {
  background-color: #37464d;
  color: white;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  width: auto;
  max-width: 40px;
}
.rigth {
  text-align: right !important;
}
.top {
  vertical-align: top;
}
</style>
